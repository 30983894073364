import TextField from '@material-ui/core/TextField'
import '../App.css';
import { useState } from 'react'
import whatPizzaWins from '../PizzaSize';
import WinningPizza from './WinningPizza';
import { Button } from '@material-ui/core';

const PizzaInputs = () => {
    const [bigPizzaSize, setBigPizzaSize] = useState('')
    const [bigPizzas, setBigPizzas] = useState(1)
    const [smallPizzaSize, setSmallPizzaSize] = useState('')
    const [smallPizzas, setSmallPizzas] = useState(2)
    const [showWinner, setShowWinner] = useState(false)

    const calulateWinningPizza = whatPizzaWins(smallPizzas, smallPizzaSize, bigPizzas, bigPizzaSize)

    if(showWinner) return <WinningPizza winningPizza={calulateWinningPizza} reset={setShowWinner} />

    const isDiabled = bigPizzas && smallPizzas && smallPizzaSize && bigPizzaSize

    return (
    <>
    <div className="pizzas">
    <div className="small-pizza-container">
      <img src='/images/small-pizza.svg' alt='small pizza' />
      <p>Smaller pizza size</p>
      <TextField 
      onChange={(e) => {
        setSmallPizzaSize(e.target.value)}} InputProps={{ inputProps: { min: 0} }} value={smallPizzaSize} type="number" variant="outlined" label='inches' />
      <p>Number of small pizza(s)</p>
      <TextField 
      onChange={(e) => {
        setSmallPizzas(e.target.value)}} InputProps={{ inputProps: { min: 0} }} value={smallPizzas} type="number" variant="outlined" label='How Many?' />
    </div>
    <div className="big-pizza-container">
      <img src='/images/big-pizza.svg' alt='big pizza' />
        <p>Bigger pizza size</p>
        <TextField onChange={(e) => {
          setBigPizzaSize(e.target.value)}} InputProps={{ inputProps: { min: 0} }} value={bigPizzaSize} type="number" variant="outlined" label='inches' />
        <p>Number of big pizza(s)</p>
        <TextField onChange={(e) => {
          setBigPizzas(e.target.value)}} InputProps={{ inputProps: { min: 0} }} value={bigPizzas} type="number" variant="outlined" label='How Many?' />
    </div>
  </div>
  <div style={{marginTop: 80}}>
        <Button
        variant="contained"
        size='large'
        disabled={!isDiabled}
        onClick={() => {
            whatPizzaWins(smallPizzas, smallPizzaSize, bigPizzas, bigPizzaSize)
            setShowWinner(true)
        }}
      >
        🍕 Whats the biggest pizza? 🍕
      </Button>
  </div>
  </>
  )
}

export default PizzaInputs