const whatPizzaWins = (smallPizzas, smallPizzaSize, bigPizzas, bigPizzaSize) => {
    if(smallPizzas && bigPizzas && bigPizzaSize !== '' && smallPizzaSize !== '') {

    const radiusSmallPizza = smallPizzaSize / 2;
    const radiusBigPizza = bigPizzaSize / 2;

    const smallPizza = Math.PI * radiusSmallPizza * radiusSmallPizza;
    const smallPizzaArea = smallPizza * smallPizzas
    const bigPizza = Math.PI * radiusBigPizza * radiusBigPizza;
    const bigPizzaArea = bigPizza * bigPizzas
    if(smallPizzaArea === bigPizzaArea) return 'same'
    if(smallPizzaArea > bigPizzaArea) return 'small'
    return 'big'
    }
}

export default whatPizzaWins