import { Button } from "@material-ui/core"
import Confetti from "react-confetti"
import '../App.css'

const imagemapper = {
    big: 'big-pizza.svg',
    small: 'small-pizza.svg',
    same: 'small-pizza.svg'
}

const WinningPizza = ({ winningPizza, reset }) => {
    if (!winningPizza) return null
    return (
        <>
        <div className='winning-pizza'>
            <Confetti />
            <img src={`images/${imagemapper[winningPizza]}`} alt={imagemapper[winningPizza]} />
            <h2>{winningPizza === 'same' ? 'They are the same? What are the chances...' : winningPizza.replace(/^\w/, (c) => c.toUpperCase())} is the most pizza!! Enjoy!</h2>
            <Button
        variant="contained"
        size='large'
        onClick={() => reset(false)}
      >
        Have another go?
      </Button>
        </div>
        <div style={{width: '100%', height: 'auto', marginTop: 28}}>
            <img style={{ maxWidth: '700px', width: '100%', height: 'auto'}} src={'images/pizzatime.gif'} alt={'its pizza time'} />
        </div>
        </>
    )
}

export default WinningPizza