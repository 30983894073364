import './App.css';
import PizzaInputs from './components/PizzaInputs';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-97991388-2";

function initGA() {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview('/homepage')
}

initGA()
function App() {
  return (
    <>
    <div className="App">
      <h1>what pizza is bigger dot com</h1>
      <PizzaInputs />
    </div>
          <footer style={{textAlign: 'center'}}>
          <h4>Made with 🍺 on a Friday night</h4>
        </footer>
        </>
  );
}

export default App;
